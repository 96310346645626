import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { Content, PostContainer, FeaturedImage } from '../components/PostTemplate/styled'

import { Title } from '../components/PostTemplate'

const PostPage = ({ data, location, pageContext }) => {
  const { title, content } = data.wordpressPost
  const { title: seoTitle, description: seoDescription, canonical } = data.wordpressPost.acf
  const featuredImage = data.wordpressPost.featured_media?.localFile.childImageSharp.fluid

  const customCrumbs = pageContext.breadcrumb.crumbs.map((crumb, i) =>
    i === 1 ? { pathname: '/noticias', crumbLabel: 'saúde' } : crumb
  )

  const adjustedPageContext = {
    ...pageContext,
    breadcrumb: { ...pageContext.breadcrumb, crumbs: customCrumbs },
  }

  return (
    <Layout pageContext={adjustedPageContext} location={location}>
      <SEO title={seoTitle} description={seoDescription} canonical={canonical} />

      <PostContainer>
        <article>
          <Title>{title}</Title>

          {featuredImage && (
            <FeaturedImage fluid={featuredImage} alt={title} loading="eager" fetchpriority="high" />
          )}

          <Content dangerouslySetInnerHTML={{ __html: content }} />
        </article>
      </PostContainer>
    </Layout>
  )
}

PostPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      author
      content
      date
      slug
      title
      acf {
        title
        description
        canonical
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default PostPage

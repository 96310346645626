import styled from 'styled-components'
import Img from 'gatsby-image'

export const TitleStyled = styled.h1`
  color: ${props => props.theme.colors.primary};
  font-size: 3.6rem;
  text-transform: uppercase;
  margin-top: 4.8rem;
  margin-bottom: 3.2rem;
  text-align: center;
  font-weight: 500;
`

export const FeaturedImage = styled(Img)`
  max-width: 780px;
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
`

export const PostContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px 72px;
  box-sizing: border-box;
`

export const Content = styled.div`
  width: 100%;

  p {
    color: ${props => props.theme.colors.brownishGrey};
    margin-bottom: 1.6rem;
    font-size: 1.6rem;
    line-height: 1.5;
  }

  img {
    max-width: 100%;
    height: 100%;
    display: block;
    margin: 1.6rem auto;
  }

  iframe {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  ul,
  ol {
    padding: 0;

    li {
      color: ${props => props.theme.colors.brownishGrey};
      font-size: 1.6rem;
      margin-bottom: 1.6rem;
      list-style-position: inside;
      line-height: 1.5;

      a {
        color: #337ab7;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.theme.colors.black};
    text-align: center;
    margin: 3.2rem 0;
    text-transform: uppercase;
    color: ${props => props.theme.colors.primary};
    font-weight: 500;

    a {
      color: ${props => props.theme.colors.primary};
    }
  }

  h1,
  h2,
  h1 a,
  h2 a {
    font-size: 3.2rem;
  }

  h3,
  h4 {
    font-size: 2.8rem;
  }

  h5,
  h6 {
    font-size: 2.4rem;
  }

  a {
    cursor: pointer;
    color: #337ab7;
    text-decoration: none;
    color: ${props => props.theme.colors.brownishGrey};
    font-size: 1.6rem;

    &:hover {
      text-decoration: underline;
    }
  }

  a.wp-block-button__link {
    margin: 3.2rem auto 3.2rem;
    display: block;
    background-color: #06214f;
    color: #ffffff;
    font-weight: 600;
    text-transform: uppercase;
    padding: 1.3rem 5.2rem;
    border-radius: 0.7rem;
    font-size: 1.3rem;
    width: fit-content;
    text-decoration: none;
  }

  .instagram-media {
    margin: 0 auto !important;
  }
`

export const SpotLight = styled.div`
  padding: 3.2rem;
  border: 1px solid ${props => props.theme.colors.primary};
  margin-bottom: 2.4rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;

  a:not(:last-child) {
    margin-bottom: 2.4rem;
  }
`

export const SpotLightTitle = styled.h2`
  color: ${props => props.theme.colors.primary};
  font-size: 2.4rem;
  margin-top: 0;
  margin-bottom: 1.6rem;
`

const ButtonStyled = styled.a`
  min-width: 100%;
  cursor: pointer;
  text-size: 1.6rem;
  text-align: center;
  outline: none;
  padding: 1.2rem;
  box-sizing: border-box;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
  font-size: 1.6rem;
`

export const ButtonOutlined = styled(ButtonStyled)`
  background-color: ${props => props.theme.colors.white};
  border-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
`

export const ButtonContained = styled(ButtonStyled)`
  background-color: ${props => props.theme.colors.primary};
  border-color: transparent;
  color: ${props => props.theme.colors.white};
`
